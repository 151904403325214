
import { defineComponent, reactive } from "vue";
import BackButton from "@/components/common/BackButton.vue";
import Card from "primevue/card";
export default defineComponent({
  name: "Order View",
  props: {
    id: String,
  },
  setup(props) {
    const state = reactive({
      id: props.id,
    });
    return { state };
  },
  components: {
    Card,
    BackButton,
  },
});
